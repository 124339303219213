// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/input.tsx");
  import.meta.hot.lastModified = "1726716723002.0098";
}
// REMIX HMR END

import React, { useEffect } from 'react';
import { useField } from 'remix-validated-form';
import { DangerAlert } from '~/components/icons/alert';
import { cn } from '~/lib/utils/utils';
export const Input = ({
  name,
  label,
  placeholder,
  required,
  icon,
  disabled,
  className,
  setValidationError,
  ...props
}) => {
  _s();
  const {
    error,
    getInputProps
  } = useField(name);
  useEffect(() => {
    if (error) {
      setValidationError && setValidationError(true);
    } else {
      setValidationError && setValidationError(false);
    }
  }, [error, setValidationError]);
  return <div>
      <label htmlFor={name} className={`${disabled && 'text-grey-200'}`}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="relative">
        <input {...props} {...getInputProps({
        id: name
      })} disabled={disabled} placeholder={placeholder} className={cn(`${error ? 'invalid' : ''} ${icon ? 'with-icon' : ''} w-full text-grey-400 disabled:bg-grey-25 disabled:!border-grey-25 disabled:border disabled:opacity-50`, className)} />
        {icon && <span className="absolute -translate-y-1/2 top-1/2 left-3">
            {icon}
          </span>}
      </div>
      {!disabled && error && <p className="pt-1 error-msg">
          <DangerAlert />
          <span className="pl-2">{error}</span>
        </p>}
    </div>;
};
_s(Input, "jKgEvOtr33S38SCRlL2FbfdQK88=", false, function () {
  return [useField];
});
_c = Input;
var _c;
$RefreshReg$(_c, "Input");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;